
#pixel-container .vsa-messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-top:5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  font-size: 16px;
}

#pixel-container .vsa-user-message-container, #pixel-container .vsa-ai-message-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 2px 0;
}

#pixel-container .vsa-user-message-container {
  align-self: flex-end;
  align-items: flex-end;
}

#pixel-container .vsa-ai-message-container {
  align-items: flex-start;
}

#pixel-container .vsa-middle-message-container {
  align-items: flex-start;
  margin: 2px 0;
}

#pixel-container .vsa-message {
  white-space: pre-wrap; 
  word-wrap: break-word; 
  overflow-wrap: break-word;
  padding: 14px;
  margin: 2px 0;
  border-radius: 10px;
  clear: both;
  font-size: 14px;
  line-height: 1.4;
  box-sizing: border-box;
}

#pixel-container .vsa-user-message {
  align-self: flex-end;
  background-color: var(--user-message);
  color: var(--user-message-text);
  border-top-right-radius: 0;
}

#pixel-container .vsa-ai-message {
  align-self: flex-start;
  text-align: left;
  width: 100%;
  background-color: var(--bot-message);
  border-top-left-radius: 0;
  color: var(--bot-message-text);
}

#pixel-container .vsa-middle-message {
  display: flex; 
  text-align: left;
  align-self: flex-start;
  background-color: #e4ded5;
  color: var(--inline-button-text);
  gap: 5px;
}

#pixel-container ::-webkit-scrollbar {
  display: none;
}

#pixel-container .vsa-user-message a {
  color: #789888;
}

#pixel-container .vsa-ai-message a {
  color: #1d9acc;
}

#pixel-container .vsa-bar-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 5px;
  align-self: flex-start;
  background-color: var(--bot-message);
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 14px;
  font-size: 13px;
  margin: 2px 0;
  box-sizing: border-box;
}

#pixel-container .vsa-bar {
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(270deg, #ebebeb, #f1f1f1, #d1d1d1);
  background-size: 200% 200%;
  animation: moving-gradient 3s linear infinite;
}

#pixel-container .vsa-bar-short {
  width: 50%;
}

#pixel-container .vsa-bar-medium {
  width: 75%;
}

#pixel-container .vsa-bar-long {
  width: 100%;
}

@keyframes moving-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#pixel-container .vsa-bars-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 75%;
  padding: 14px;
  font-size: 13px;
  margin: 4px 0;
}

/*  Thumbs */
#pixel-container .vsa-feedback-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px; 
}

.enum-options-button-container {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 400px;
  position: relative;
}

.enum-option-button {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 1s forwards;
  background-color: white;
  border: 1px solid #000B51;
  color: #000B51;
  text-align: left;
  padding: 3px 13px;
  border-radius: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
  z-index: 999;
}

.enum-option-button:last-child {
  margin-bottom: 0;
}

.enum-option-button:hover {
  background-color: #000B51;
  color: white;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.enum-option-button.custom-font-button {
  font-optical-sizing: auto;
  font-weight: 450;
  font-style: normal;
}

.enum-option-button.selected {
  background-color: #000B51;
  color: white;
  border: 1px solid #000B51;
  opacity: 1;
}
