#pixel-container .vsa-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: var(--navbar-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 0 10px; 
}

#pixel-container .vsa-nav-icon {
    flex-grow: 1;
    display: flex; 
    justify-content: center;
    align-items: center;
}

#pixel-container .vsa-nav-icon-back-button {
    background-color: black; 
    color: white; 
    border-radius: 8px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
}

#pixel-container .vsa-nav-icon img {
    max-height: 100%; 
}

#pixel-container .vsa-icon-active {
    filter: var(--icon-active-filter)
}
#pixel-container .vsa-icon-inactive {
    filter: var(--icon-inactive-filter)
}
  
#pixel-container .vsa-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px; 
    background: var(--background-color);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-bottom: 1px solid #ccc;
    margin: 0px !important;
    padding:0px !important;
}

#pixel-container .vsa-mobile-top-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px; 
    background: var(--background-color);
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
    border-bottom: 1px solid #ccc;
}

#pixel-container .vsa-back-button {
    color: #696966; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    margin-top: 4px;
    font-size: 32px;
    width: 32px;
}

#pixel-container .vsa-del-button {
    color: #232323; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    margin-right: 5px;
    font-size: 24px;
    width: 24px;
}

#pixel-container .vsa-del-button-mobile {
    color: #232323; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    margin-top: 0px;
    margin-right: 8px;
    font-size: 24px;
    width: 24px;
}

#pixel-container .vsa-text {
    margin-left: 10px; 
    color: var(--text-color-main); 
    font-size: 16px;
    font-weight: bold;
    flex-grow: 1;
    text-align: center; 
}
#pixel-container .vsa-nav1 {
    display: flex;
    align-items: center;
    margin-left: 20px;
}
#pixel-container .vsa-nav2 {
    display: flex; 
    align-items: center; 
    margin-right: 20px; 
}

.cosailor-heading {
    color: #000 !important; /* Use the desired color */
}