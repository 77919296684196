#pixel-container .vsa-custom-query-area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
    background-color: var(--background-color) !important;
    color: var(--text-color-main) !important;
    border-top: 1px solid #ccc !important;
    position: relative;
    bottom: 0;
    width: 100%;
}