#pixel-container .vsa-progress-input-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: var(--background-color);
    color:var(--text-color-main);
    border-top: 1px solid #ccc;
}

#pixel-container .vsa-progress-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;
}
  
#pixel-container .vsa-progress-circle {
    border: 4px solid #dddbd9; 
    border-top: 4px solid #666666;
    border-radius: 50%;
    width: 40px; 
    height: 40px; 
    animation: spin 1.4s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
#pixel-container .vsa-stop-square {
    width: 13px;
    height: 13px;
    background-color: #666666; 
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}
  