/* Default theme */

#pixel-container {
    --background-color: #f6f6f6;
    --secondary-color: #161616;
    --text-color-main: #161616;
    --text-color-secondary: #FFFFFF;

    --user-message: #003764;
    --user-message-text: #ffffff;
    --bot-message: #ffffff;
    --bot-message-text: #161616;
    
    --inline-button-color: #dbd4c8;
    --inline-button-text: #161616;

    --border-color: #dadada;
    --input-border-color:#e7decd;
    --input-background-color:#ffffff;
    --text-color-light:#edf2fc;

    --navbar-color: #f6f6f6;
    --icon-active-filter: brightness(0) saturate(100%) invert(100%) sepia(16%) saturate(7398%) hue-rotate(345deg) brightness(114%) contrast(75%);
    --icon-inactive-filter: brightness(0) saturate(100%) invert(85%) sepia(33%) saturate(439%) hue-rotate(107deg) brightness(103%) contrast(105%);
}



/* Dark Mode */
#pixel-container .theme-1 {
    --background-color: #161616;
    --secondary-color: #2e2e2e;
    --navbar-color: #1F1E1E;
    --text-color-main: #FFFFFF;
    --text-color-secondary: #000000;
    --border-color: #2e2e2e;
    --icon-active-filter: brightness(0) saturate(100%) invert(100%) sepia(16%) saturate(7398%) hue-rotate(345deg) brightness(114%) contrast(75%);
    --icon-inactive-filter: brightness(0) saturate(100%) invert(85%) sepia(33%) saturate(439%) hue-rotate(107deg) brightness(103%) contrast(105%);
}

/* Light Mode */
#pixel-container .theme-2 {
    --background-color: #f6f6f6;
    --secondary-color: #161616;
    --text-color-main: #161616;
    --text-color-secondary: #FFFFFF;

    --user-message: #003764;
    --user-message-text: #ffffff;
    --bot-message: #ffffff;
    --bot-message-text: #161616;
    
    --inline-button-color: #dbd4c8;
    --inline-button-text: #161616;

    --border-color: #dadada;
    --input-border-color:#e7decd;
    --input-background-color:#ffffff;
    --text-color-light:#edf2fc;

    --navbar-color: #f6f6f6;
    --icon-active-filter: brightness(0) saturate(100%) invert(100%) sepia(16%) saturate(7398%) hue-rotate(345deg) brightness(114%) contrast(75%);
    --icon-inactive-filter: brightness(0) saturate(100%) invert(85%) sepia(33%) saturate(439%) hue-rotate(107deg) brightness(103%) contrast(105%);
}
